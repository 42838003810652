.text-shpere {
    top: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tagcloud {
    display: inline-block;
    top: 0;
    left: 0;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    letter-spacing: 0.0625em;
    font-size: 1em;
}

/* Change color of each text in sphere on hover   */
.tagcloud--item {
    color: rgb(52, 198, 198);
    text-transform: uppercase;
}

.tagcloud--item:hover {
    color: #ce3f26;
}