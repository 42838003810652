.Todo {
    display: flex;
    margin: 8px 3px 0 -2.5rem;
    padding: 0.3rem 2rem;
    height: 60px;
    justify-content: space-between;
    align-items: center;
    background: rgba(255, 255, 255, 0.2);
    overflow: hidden;
    position: relative;
    transition: opacity 500ms ease-in-out;
}

.Todo-task {
    position: relative;
    transition: opacity 0.4s linear;
}

.Todo.completed {
    transition: opacity 300ms ease-in-out;
    transition-delay: 200ms;
    opacity: 0.5;
}

/* .Todo .Todo-task {
    opacity: 0.6;
} */

.Todo.Todo-task:before {
    content: "";
    position: absolute;
    top: 50%;
    left: -0.5rem;
    display: block;
    width: 0%;
    height: 4px;
    background: #fff;
    animation: strikeitem 0.3s ease-in 0s forwards;
}

.Todo-task:hover {
    cursor: pointer;
}

#completed {
    opacity: 0.6;
}

#completed:before {
    content: "";
    position: absolute;
    top: 50%;
    left: -0.5rem;
    display: block;
    width: 0%;
    height: 4px;
    background: #fff;
    animation: strikeitem 0.3s ease-in 0s forwards;
}

@keyframes strikeitem {
    to {
        width: calc(100% + 1rem);
    }
}

.Todo-buttons {
    flex-shrink: 0;
    padding-left: 0.7em;
    margin-left: auto;
}

.Todo-buttons button {
    border: none;
    font-size: 1em;
    margin: 0.4em;
    background: none;
    -webkit-appearance: none;
    cursor: pointer;
    color: #fff;
}

.Todo-edit-form {
    display: flex;
    flex-wrap: wrap;
}

.Todo-edit-form label {
    min-width: 100%;
    margin-bottom: 0.5rem;
    font-size: 1.3rem;
}

.Todo-edit-form input {
    flex-grow: 1;
    border: none;
    background: #f7f1f1;
    padding: 0 1.5em;
    font-size: initial;
}

.Todo-edit-form button {
    padding: 0 1.3rem;
    border: none;
    background: #ff6666;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    border: 1px solid rgba(255, 255, 255, 0.3);
    margin-left: 5px;
    cursor: pointer;
    transition: background 0.2s ease-out;
}

.Todo-edit-form button:hover {
    background: #ff5e5e;
}

.Todo-edit-form input,
.Todo-edit-form button {
    font-family: "Quicksand", sans-serif;
    height: 3rem;
}