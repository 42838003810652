.TodoList {
    margin: 4rem auto;
    padding: 2rem 3rem 3rem;
    max-width: 600px;
    background: #ff6666;
    color: #fff;
    box-shadow: -20px -20px 0px 0px rgba(100, 100, 100, 0.1);
    overflow: hidden;
    border-radius: 8px;
}

.TodoList ul {
    margin-top: 2.6rem;
    list-style: none;
}

.TodoList h1 {
    font-weight: normal;
    font-size: 2.6rem;
    letter-spacing: 0.05em;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.TodoList h1 span {
    display: block;
    font-size: 0.8rem;
    margin-bottom: 0.7rem;
    margin-left: 3px;
    margin-top: 0.2rem;
}

.todo-enter {
    opacity: 0;
}

.todo-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
}

.todo-exit {
    opacity: 1;
}

.todo-exit-active {
    /* opacity: 0; */
    opacity: 0;
    transform: translateX(-100%);
    transition: all 500ms ease-in-out;
}

.Todo .Todo-buttons i {
    display: inline-block;
}

.form-enter {
    transform: translateY(77px);
    transition: all 500ms cubic-bezier(0.39, 0.575, 0.565, 1);
    position: absolute;
}

.form-enter-active {
    transform: translateY(0%);
    transition: all 500ms cubic-bezier(0.39, 0.575, 0.565, 1);
    position: absolute;
}

.form-exit {
    transform: translateY(0%);
    position: absolute;
}

.form-exit-active {
    transform: translateY(77px);
    transition: all 500ms cubic-bezier(0.39, 0.575, 0.565, 1);
    position: absolute;
}

.task-text-enter {
    transform: translateY(-77px);
    position: absolute;
}

.task-text-enter-active {
    transform: translateY(0);
    transition: all 500ms cubic-bezier(0.39, 0.575, 0.565, 1);
    position: absolute;
}

.task-text-exit {
    transform: translateY(0);
    position: absolute;
}

.task-text-exit-active {
    transform: translateY(-77px);
    position: absolute;
    transition: all 500ms cubic-bezier(0.39, 0.575, 0.565, 1);
}