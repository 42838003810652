.NewTodoForm {
    margin-top: 3rem;
    display: flex;
    flex-wrap: wrap;
}



.NewTodoForm label {
    min-width: 100%;
    margin-bottom: 0.5rem;
    font-size: 1.3rem;
}

.NewTodoForm input {
    flex-grow: 1;
    border: none;
    background: #f7f1f1;
    padding: 0 1.5em;
    font-size: initial;
    outline: none;
}

.NewTodoForm button {
    padding: 0 1.3rem;
    border: none;
    background: rgba(255, 255, 255, 0.2);
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    border: 1px solid rgba(255, 255, 255, 0.3);
    margin-left: 5px;
    cursor: pointer;
    transition: background 0.4s ease-out;
    outline: none;
}

.NewTodoForm button:hover {
    background: rgba(0, 0, 0, 0.1);
}

.NewTodoForm input,
.NewTodoForm button {
    font-family: "Quicksand", sans-serif;
    height: 3rem;
}

@media only screen and (max-width: 505px) {
    .NewTodoForm {
        justify-content: center;
    }

    .NewTodoForm button {
        margin-top: 1.5em;
    }

    .NewTodoForm label {
        min-width: 100%;
        margin-bottom: 0.5rem;
        font-size: 1rem;
    }
}